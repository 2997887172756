import React, { useEffect, useState } from "react";
import styles from "./Individual.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import PayoutHistory from "./PayoutHistory";
import axios from "axios";

const Individual = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get("/sanctum/csrf-cookie");
        const response = await axios.get(`api/getIndividual`);
        if (response.data.status === 200) {
          setSales(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    if (sales.length === 0) {
      fetchData();
    }
  }, [sales]);

  return (
    <>
      <div className={styles.section}>
        <PayoutHistory items={sales} loading={loading} />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Individual;
