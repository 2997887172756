import axios from "axios";

axios.defaults.baseURL = 'https://daleelnfc.com/';
export const imageUrl = 'https://daleelnfc.com/storage'
export const baseURL = 'https://daleelnfc.com/api'

// axios.defaults.baseURL = 'http://localhost:8000/';
// export const imageUrl = 'http://localhost:8000/storage'
// export const baseURL = 'http://localhost:8000/api'

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
})