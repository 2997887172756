import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import styles from "./Approved.module.sass";
import Card from "../../../../components/Card";
import Form from "../../../../components/Form";
import Icon from "../../../../components/Icon";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import DivCard from "../../../../components/CardDiv";
import QRCodeStyling from "qr-code-styling";
import swal from "sweetalert";

const Approved = ({ className, values }) => {
  const [url, setUrl] = useState("https://qr-code-styling.com");
  const [fileExt, setFileExt] = useState("png");
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [getstatus, setGetStatus] = useState([]);
  const { id } = useParams();

  // Ref to store the QR code instances for each item
  const qrRefs = useRef([]);

  // Fetch sales and status data
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/getSalesCards/${id}`).then((res) => {
        if (res.data.status === 200) {
          setSales(res.data.data);
        }
        setLoading(false);
      });
    });
  }, [id]);

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/ReadyStatus/${id}`).then((res) => {
        if (res.data.status === 200) {
          setGetStatus(res.data.data);
        }
        setLoading(false);
      });
    });
  }, [id]);

  const handleReadyToShip = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.post(`api/ReadyStatus/${id}`).then((res) => {
        if (res.data.status === 200) {
          swal("Successfull!", "Account has been ReadyToShip.", "success");
          window.location.reload();
        }
      });
    });
  };

  const handleShipped = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.post(`api/ShippedStatus/${id}`).then((res) => {
        if (res.data.status === 200) {
          swal("Successfull!", "Account has been Shipped.", "success");
          window.location.reload();
        }
      });
    });
  };

  const handleApproved = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.post(`api/ApprovedStatus/${id}`).then((res) => {
        if (res.data.status === 200) {
          swal("Successfull!", "Account has been Approved.", "success");
          window.location.reload();
        }
      });
    });
  };

  // Handle copy to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("URL copied");
    }).catch((error) => {
      console.error("Failed to copy: ", error);
    });
  };

  // Handle URL input change
  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };

  // Handle file extension change
  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  // Download QR code for each sale
  const onDownloadClick = (index) => {
    qrRefs.current[index].download({
      extension: fileExt
    });
  };

  // Get status color for each item
  const getStatusColorClass = (status) => {
    switch (status) {
      case "active":
        return styles.active;
      case "notactive":
        return styles.notactive;
      default:
        return "";
    }
  };

  useEffect(() => {
    // Loop through sales and initialize QR codes only if they haven't been initialized yet
    sales.forEach((sale, index) => {
      if (!qrRefs.current[index]) {
        qrRefs.current[index] = new QRCodeStyling({
          width: 300,
          height: 300,
          dotsOptions: {
            color: "#000",
            type: "square"
          },
          imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
          },
          data: `https://daleelnfc.com/ViewProfile/${sale.url}` // Generate QR code based on sale's URL
        });
      }

      // Append the QR code to the ref div if it's not already in the DOM
      if (qrRefs.current[index]) {
        const qrContainer = document.getElementById(`qr-container-${index}`);
        if (qrContainer && !qrContainer.hasChildNodes()) {
          qrRefs.current[index].append(qrContainer);
        }
      }
    });
  }, [sales]); // Trigger this whenever sales are updated

  return (
    <>
      {loading ? (
        <div className={styles.shop}>
          <Card
            className={styles.card}
            title={getstatus.name}
            classTitle={cn("title-purple", styles.title)}
            classCardHead={styles.head}
            head={
              <>
                <Form
                  className={styles.form}
                  value={search}
                  setValue={setSearch}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                  type="text"
                  name="search"
                  icon="search"
                />
              </>
            }
          >
            <center>
              <ScaleLoader color="#2a4f99" />
            </center>
          </Card>
        </div>
      ) : (
        <DivCard
          className={styles.card}
          title={getstatus.name}
          classTitle={cn("title-purple", styles.title)}
          classCardHead={styles.head}
          head={
            <>
              <Form
                className={styles.form}
                value={search}
                setValue={setSearch}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
                type="text"
                name="search"
                icon="search"
              />
            </>
          }
        >
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>Status</div>
                <div className={styles.col}>User Email</div>
                <div className={styles.col}>URL</div>
                <div className={styles.col}>QR Code</div>
                <div className={styles.col}>Download</div>
              </div>
              {sales
                .filter((item) => {
                  return search.toLowerCase() === "" 
                    ? item 
                    : item.user === null 
                    ? "" 
                    : item.user.toLowerCase().includes(search);
                })
                .map((x, index) => (
                  <div className={styles.row} key={x.id}>
                    <div className={cn(styles.col)}>
                      <span className={getStatusColorClass(x.status)}>
                        {x.status}
                      </span>
                    </div>

                    <div className={cn(styles.col)}>
                      {x.user ? (
                        <Link
                          to={`https://daleelnfc.com/ViewProfile/${x.url}`}
                          className={styles.col}
                        >
                          {x.user}
                        </Link>
                      ) : (
                        "x"
                      )}
                    </div>

                    <div className={cn(styles.col)}>
                      <button
                        onClick={() => copyToClipboard(`https://daleelnfc.com/ViewProfile/${x.url}`)}
                      >
                        <Icon name="copy" size="30" className={styles.svgi} />
                      </button>
                    </div>

                    {/* QR Code container */}
                    <div className={cn(styles.col)} id={`qr-container-${index}`} />
                    
                    {/* Download QR Code */}
                    <div className={cn(styles.col)}>
                      <button onClick={() => onDownloadClick(index)} className={styles.details}>
                        Download QR
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </DivCard>
      )}
    </>
  );
};

export default Approved;